@import url(//fonts.googleapis.com/css?family=Open+Sans);
.SpinnerContent {
    position: absolute;
    margin-top: -7px;
    margin-left: 7px;
}

#last-movements-title {
    padding: 0 10px;
    font-size: 12px;
}

.noReceipt {
    text-align: center;
    margin-top: 50px !important;
}

:root {
    --ion-toolbar-background: #188fff;
}

#header {
    height: 5vh;
    padding: 0 3% 0 4%;
    display: flex;
    align-items: center;
}

#nav-title {
    float: left;
    font-size: 15px;
    color: white;
    padding: 0;
    margin-left: 7px;
    font-weight: lighter;
}

#nav-image {
    margin-top: 0.1vh;
    float: left;
    height: 20px;
}

#settings-icon {
    color: white;
    float: right;
    font-size: 20px;
    cursor: pointer;
}

.receipt-item-container {
    font-size: smaller;
    display: flex !important;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.receipt-item-icon {
    padding: 0;
    margin: auto;
    border: 2px;
    font-family: 'Montserrat', sans-serif;
}

.receipt-item-data {
    padding: 0;
    margin: auto auto auto 10px;
    border: none;
}

.receipt-item-date {
    text-align: right;
    padding: 0;
    margin: auto auto auto 10px;
    border: none;
}

#client-avatar-container {
    padding: 20px;
    display: flex;
}

#client-avatar-label {
    margin-left: 10px;
    margin-top: 10px;
    color: grey;
    float: left;
    font-size: small;
}

.SpinnerContent {
    position: absolute;
    margin-top: -7px;
    margin-left: 7px;
}

#client-title-container {
    display: flex;
    justify-content: space-between;
    color: grey;
    padding: 0 10px;
}

#last-accounting-title {
    font-size: 12px;
}

#checkbox-container {
    display: flex;
    justify-content: space-between;
    color: grey;
    font-size: 12px;
    padding: 0 10px;
}

#checkbox {
    margin-right: 15px;
    margin-top: 1px;
}

.bill_modal {
    padding: 5% 5% 0% 5%;
}

.close_future {
    margin-left: 10%;
    size: 2px;
}

ion-fab-list ion-fab-button[title]::after {
    position: absolute;
    top: 4px;
    right: 45px;

    color: white;
    background-color: rgba(0,0,0,0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;

    content: attr(title);
    z-index: 1;
}

.loadSpinner {
    text-align: center;
    margin-top: 10px;
}

.receiptDetails {
    text-align: center;
    margin-top: 10px;
}

.h3 {
    font-weight: 500;
}

.Receipt-Folio {
    text-align: center;
    margin-top: 5%;
    margin-left: 5%;
    width: -webkit-max-content;
    width: max-content;
}

.adminsettings-item {
    --padding-end: 20px;
    --inner-border-width: 0px;
    --min-height: 60px;
    color: grey;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.adminsettings-label {
    white-space: normal !important;
    cursor: pointer;
}

#billables {
    border-bottom: 2px gray solid;
    --min-height: 75px;
    align-items: self-start;
    margin-top: 10px;
}

#clienttypes-assing {
    margin-top: 25px;
}

@media (max-width: 576px) {
    .billables-catalog-col-title,
    .billables-catalog-col-list {
        width: 100% !important;
        max-width: 100% !important;
    }

    .billables-catalog-row-title,
    .billables-catalog-row-list {
        display: grid;
    }

    .billables-catalog-col-list {
        border-top: 1px solid gray;
    }

    .billables-catalog-row-list {
        margin-bottom: 0.5em;
    }

    .billables-catalog-row-list :last-child {
        border-bottom: 0px;
    }

    .billables-catalog-list :first-child .billables-catalog-row-list {
        border-top: 0px !important;
    }
}

.billables-catalog-title {
    color: black;
    font-size: x-large;
    margin-bottom: 0.5em;
    display: flex;
}

.billables-catalog-col-title {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.billables-catalog-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.billables-catalog-list :first-child .billables-catalog-row-list {
    border: 1px solid gray;
}

.billables-catalog-grid-list {
    padding-top: 0px;
    padding-bottom: 0px;
}

.billables-catalog-row-list {
    border: 1px solid gray;
    border-top: 0px;
}

.billables-catalog-col-list {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.billables-catalog-footer {
    display: grid;
    justify-content: end;
    margin: 1em 0 1em 0;
}

.billables-catalog-footer::before {
    height: 0px;
}

.add-bill-button {
    display: grid;
    justify-content: space-between;
    --background: #6558f5 !important;
}

.add-bill-button-icon {
    margin: 0 0.2em 0 0;
    font-size: 22px;
}

.noBillables {
    text-align: center;
    margin-top: 50px !important;
}

.data-bill {
    border: 1px solid #f3f3f3;
}

.amount {
    caret-color: var(--ion-color-primary);
    font-size: 1.8em;
}

.cash-icon {
    font-size: 2.2em;
    font-weight: bolder;
    text-align: right;
    align-self: center;
    color: var(--ion-color-primary);
}
.data-column {
    color: var(--ion-color-dark);
    text-align: right;
    font-size: 0.7em;
}

.overdue {
    color: var(--ion-color-danger);
}

.title-column {
    font-size: 0.8em;
}

.title-pay {
    font-weight: bold;
}

.separation {
    border-top: 1px solid #e9e9e9;
}

.table-receipts {
    border: 1px solid #e9e9e9;
}

.labels {
    font-size: 0.6em;
}

.first-row-receipt {
    font-size: 0.8em;
}

.sec-row-receipt {
    font-size: 0.7em;
}

.receipt-col > .sec-row-receipt {
    justify-content: flex-end;
    padding-right: 0.5em;
}
.receipt-col > .first-row-receipt {
    justify-content: flex-end;
    padding-right: 0.5em;
}

.selected {
    font-size: 0.7em;
}

.pay-details {
    margin-bottom: 40px;
}

.compact {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 80;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.workspace-title {
    font-size: small !important;
    font-family: 'Montserrat';
    color: 'text.secondary';
    margin-left: 0%;
    border-color: transparent;
}

.workspace-content {
    font-size: small !important;
    font-family: 'Montserrat', sans-serif;
    color: 'grey';
    font-weight: 80;
    background-color: pink;
    border-color: transparent;
}

.workspace-button {
    font-size: xx-small !important;
    font-family: 'Montserrat', sans-serif;
    color: 'text.secondary';
    font-weight: 80;
    margin-left: 1%;
}

.option {
    background-color: white;
    color: gray;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #3880ff;
    --ion-color-warning-rgb: 55, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #3880ff;
    --ion-color-warning-tint: #3880ff;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: rgb(90, 88, 88);
    --ion-text-font: 'text-custom';
    --ion-text-color-rgb: 0, 0, 0;
    --ion-font-weight: 'light';

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    --ion-item-background: #ffffff;

    --ion-tab-bar-background: #3880ff;

    --ion-card-background: #ffffff;

    --ion-icon-color: blue;
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.color-text {
    color: grey;
}

.content {
    padding: 3% 3% 0 3%;
    color: grey;
}

.input {
    background: transparent;
    width: 100%;
    border: none;
    padding: 0 15px;
    font-size: small;
    color: #0d0d0d;
}

.input:focus-visible {
    outline: none;
}

.list {
    padding: 0;
    margin: -3% 0 2% 0;
    border: 1px solid rgba(212, 205, 212, 0.5);
    overflow: auto;
}

.modal {
    padding: 0;
    margin: -3% 0 2% 0;
    border: 1px solid rgba(212, 205, 212, 0.5);
    overflow: auto;
}

.ion-item1 {
    font-family: 'Montserrat';
    font-size: small;
    --ion-text-color: rgb(90, 88, 88);
    color: grey;
    border-color: transparent;
}

.ion-item {
    font-family: 'Montserrat';
    font-size: small;
    margin-left: auto;
    --ion-text-color: rgb(90, 88, 88);
    color: grey;
}

.ion-item-balance {
    font-family: 'Montserrat';
    font-size: small;
    margin-left: auto;
    --ion-text-color: rgb(90, 88, 88);
    color: green;
}
.page-background-color {
    background: white;
}

.balance {
    color: green;
}

.ion-title {
    font-family: 'Montserrat';
    font-size: medium;
    margin-right: auto;
    color: grey;
}

.receipt-item-icon {
    padding: 0;
    margin: auto;
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    font-weight: 1px;
}

.ion-title-top {
    font-family: 'Montserrat';
    font-size: medium;
}

.checkbox {
    font-family: 'Montserrat';
    font-size: medium;
    margin-left: 80%;
    color: grey;
}

.checkbox1 {
    font-family: 'Montserrat';
    color: grey;
}

.icon {
    font-family: 'Montserrat';
    color: grey;
    size: 20px;
}

.ion-padding {
    font-family: 'Montserrat';
    margin: auto;
    margin-left: 5%;
}

.avatar {
    margin-top: -5px;
    margin-left: -3%;
}

