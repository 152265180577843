.adminsettings-item {
    --padding-end: 20px;
    --inner-border-width: 0px;
    --min-height: 60px;
    color: grey;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.adminsettings-label {
    white-space: normal !important;
    cursor: pointer;
}

#billables {
    border-bottom: 2px gray solid;
    --min-height: 75px;
    align-items: self-start;
    margin-top: 10px;
}

#clienttypes-assing {
    margin-top: 25px;
}
