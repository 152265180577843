@media (max-width: 576px) {
    .billables-catalog-col-title,
    .billables-catalog-col-list {
        width: 100% !important;
        max-width: 100% !important;
    }

    .billables-catalog-row-title,
    .billables-catalog-row-list {
        display: grid;
    }

    .billables-catalog-col-list {
        border-top: 1px solid gray;
    }

    .billables-catalog-row-list {
        margin-bottom: 0.5em;
    }

    .billables-catalog-row-list :last-child {
        border-bottom: 0px;
    }

    .billables-catalog-list :first-child .billables-catalog-row-list {
        border-top: 0px !important;
    }
}

.billables-catalog-title {
    color: black;
    font-size: x-large;
    margin-bottom: 0.5em;
    display: flex;
}

.billables-catalog-col-title {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.billables-catalog-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.billables-catalog-list :first-child .billables-catalog-row-list {
    border: 1px solid gray;
}

.billables-catalog-grid-list {
    padding-top: 0px;
    padding-bottom: 0px;
}

.billables-catalog-row-list {
    border: 1px solid gray;
    border-top: 0px;
}

.billables-catalog-col-list {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.billables-catalog-footer {
    display: grid;
    justify-content: end;
    margin: 1em 0 1em 0;
}

.billables-catalog-footer::before {
    height: 0px;
}

.add-bill-button {
    display: grid;
    justify-content: space-between;
    --background: #6558f5 !important;
}

.add-bill-button-icon {
    margin: 0 0.2em 0 0;
    font-size: 22px;
}

.noBillables {
    text-align: center;
    margin-top: 50px !important;
}
