:root {
    --ion-toolbar-background: #188fff;
}

#header {
    height: 5vh;
    padding: 0 3% 0 4%;
    display: flex;
    align-items: center;
}

#nav-title {
    float: left;
    font-size: 15px;
    color: white;
    padding: 0;
    margin-left: 7px;
    font-weight: lighter;
}

#nav-image {
    margin-top: 0.1vh;
    float: left;
    height: 20px;
}

#settings-icon {
    color: white;
    float: right;
    font-size: 20px;
    cursor: pointer;
}
