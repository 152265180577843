.loadSpinner {
    text-align: center;
    margin-top: 10px;
}

.receiptDetails {
    text-align: center;
    margin-top: 10px;
}

.h3 {
    font-weight: 500;
}

.Receipt-Folio {
    text-align: center;
    margin-top: 5%;
    margin-left: 5%;
    width: max-content;
}
